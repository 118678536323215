<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters">
      <div slot="content-buttons">
        <Button _key="btnProductCreate" title="Produto" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="productCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "productListView",
  components: {
    ViewTemplateWithTable,
    Button,
  },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Produto",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/stock/product/get-all",
        urlDeleteAllApi: "/api/v1/stock/product/delete",
        showChecks: true,
        headerTable: [
          {
            field: "photo",
            container: "nixloc-photo-product",
            type: "image",
          },
          {
            field: "fullName",
            title: "Nome",
            type: "link",
            eventName: "productUpdate",
            iconSearch: true,
            classCssBody: "center-vertical",
          },
          {
            field: "categoryProductColorHtml",
            type: "html",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "manufacturerName",
            title: "Fabricante",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "totalStockName",
            title: "Estoque",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center center-vertical badge badge-info",
                fieldComparison: "Ativo",
              },
              {
                classCss: "text-center center-vertical badge badge-danger",
                fieldComparison: "Inativo",
              },
            ],
          },
        ],
      },
      filters: [
        {
          type: "multiOptions",
          title: "Categoria",
          field: "CategoryProductName",
          url: "/api/v1/stock/category-product/select-all",
          value: "",
        },
        {
          type: "multiOptions",
          title: "Fabricante",
          field: "ManufacturerName",
          url: "/api/v1/stock/manufacturer/select-all",
          value: "",
        },
        {
          type: "patrimony",
          title: "Patrimonio",
          field: "ListPatrimony",
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Ativo", value: "Ativo" },
            { text: "Inativo", value: "Inativo" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Tipo",
          field: "TypeName",
          options: [
            { text: "Acessório", value: "Acessório" },
            { text: "Equipamento", value: "Equipamento" },
            { text: "Kit", value: "Kit" },
            { text: "Material", value: "Material" },
            { text: "Serviço", value: "Serviço" },
          ],
          value: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "productSelectOption",
      });
      this.removeLoading(["btnProductCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "productUpdate") {
          if (event.data.typeName == "Kit") {
            this.$router.push({
              name: "kitRentCreate",
              params: { id: event.data.id },
            });
          } else {
            this.$router.push({
              name: "productUpdate",
              params: { id: event.data.id, type: event.data.type },
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>